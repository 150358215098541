import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import Reveal from 'react-reveal/Reveal'

import StandardPadding from './standardPadding'
import RedButton from './redButton'

import styles from '../styles/components/accelerator-card.module.scss'
import styleVars from '../styles/variables.scss'

import finchHeadRed from '../images/finch-head-red.png'

const listItems = [
  '3 month timeline',
  'Curated program for your startup’s unique needs',
  'Mentorship from experts and founders',
  'Intensive stress testing, refining pitch and improving product workshops',
  'Full service creative, development and product team at your disposal*',
  'Networking and pitch opportunities with founders and VCs',
  '1-5% equity in your company and operating expenses charged for creative, dev and product team work*',
]

const AcceleratorCard = () => {
  const { background } = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "accelerator-card-background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className={styles.container}>
      <Reveal effect="fadeInUp">
        <StandardPadding className={styles.card}>
          <Img
            fluid={background.childImageSharp.fluid}
            alt=""
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: -1,
              backgroundColor: styleVars.primaryBrandBlue,
            }}
          />
          <img src={finchHeadRed} alt="Finch Head" />
          <h2>
            15 Finches
            <br />
            <span className={styles.red}>Startup Partnership Program</span>
          </h2>
          <div className={styles.flexContainer}>
            <div>
              <h3>The Program</h3>
              <p className={styles.description}>
                Offering the best early-stage startups access to mentorship,
                transformational workshops and an array of creative, development
                and product services during the three month program. Accepted
                startups will receive a unique program schedule as well as
                networking and pitch opportunities with founders and VCs.
              </p>
              <p className={styles.info}>Rolling Admission - Apply anytime</p>
              <div className={styles.buttonContainer}>
                <RedButton text="Learn More" to="/partnership-program/" />
              </div>
            </div>
            <div>
              <h3>What it entails</h3>
              <ul>
                {listItems.map(item => (
                  <li>{item}</li>
                ))}
              </ul>
              <p className={styles.info}>
                *Fees and equity will be subject to review, negotiations and
                approval on a company basis.
              </p>
            </div>
          </div>
        </StandardPadding>
      </Reveal>
    </section>
  )
}

export default AcceleratorCard
