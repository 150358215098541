import React from 'react'
import Img from 'gatsby-image'
import Scroll from 'react-scroll'
import { Link } from 'gatsby'
import styles from '../styles/components/case-study-item.module.scss'
import classNames from 'classnames/bind'
import blueArrow from '../images/blue-arrow-svg.svg'
import ScrollElement from './scrollElement'

import rightArrowBlue from '../images/right-arrow-blue.png'
import rightArrowDark from '../images/right-arrow-dark.png'
import rightArrowWhite from '../images/right-arrow-white.png'

let cx = classNames.bind(styles)

let ScrollLink = Scroll.Link
let scroll = Scroll.animateScroll

let screenWidth
let isMobile = false

const Parallaxer = React.forwardRef((props, ref) => {
  const top = this.ref.current.getBoundingClientRect().top
  const height = this.ref.current.getBoundingClientRect().bottom - top
  let percentShown = (window.innerHeight - top) / (height + window.innerHeight)
  if (percentShown < 0) percentShown = 0
  else if (percentShown > 1) percentShown = 1
  return (
    <div style={{ transform: `translateY(${props.offsetY * percentShown}px)` }}>
      {props.children}
    </div>
  )
})

const CaseStudyItem = class extends React.Component {
  constructor(props) {
    super(props)
    //console.log('hi in case study item constructor: slug is ' + this.props.slug)
    this.state = {
      isMouseInHoverBox: false,
      arrowURL: this.props.homePage ? rightArrowDark : rightArrowWhite,
      topLayerOpacity: 1,
      middleLayerOpacity: 1,
    }
    this.containerRef = React.createRef()
    this.handleScroll = this.handleScroll.bind(this)

    /* create refs for parallax elements */
    this.imageRef = React.createRef()
    this.keywordsRef = React.createRef()
    this.mainTitleRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    screenWidth = window.innerWidth
    if (screenWidth <= 768) {
      isMobile = true
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  parallax() {
    if (typeof window !== undefined) {
      /* Calculate ImageRef Parallax */
      const offset = this.imageRef.current.attributes.parallaxoffset.value
      const top = this.imageRef.current.getBoundingClientRect().top
      const height = this.imageRef.current.getBoundingClientRect().bottom - top
      let percentShown =
        (window.innerHeight - top) / (height + window.innerHeight)
      if (percentShown < 0) percentShown = 0
      else if (percentShown > 1) percentShown = 1
      this.imageRef.style.transform = `translateY(${offset * percentShown}px)`
    }
  }

  handleScroll() {
    if (typeof window !== undefined) {
      /* Calculate ImageRef Parallax */
      if (this.imageRef !== null) {
        let offset = this.imageRef.current.attributes.parallaxoffset.value
        let top = this.imageRef.current.getBoundingClientRect().top
        let height = this.imageRef.current.getBoundingClientRect().bottom - top
        let percentShown =
          (window.innerHeight - top) / (height + window.innerHeight)
        if (percentShown < 0) percentShown = 0
        else if (percentShown > 1) percentShown = 1
        this.imageRef.current.style.transform = `translateY(${offset *
          percentShown}px)`
      }

      /* Calculate KeywordsRef Parallax */
      if (this.keywordsRef !== null) {
        let offset = this.keywordsRef.current.attributes.parallaxoffset.value
        let top = this.keywordsRef.current.getBoundingClientRect().top
        let height =
          this.keywordsRef.current.getBoundingClientRect().bottom - top
        let percentShown =
          (window.innerHeight - top) / (height + window.innerHeight)
        if (percentShown < 0) percentShown = 0
        else if (percentShown > 1) percentShown = 1
        this.keywordsRef.current.style.transform = `translateY(${offset *
          percentShown}px)`
      }

      /* Calculate MainTitleRef Parallax */
      if (this.mainTitleRef !== null) {
        let offset = this.mainTitleRef.current.attributes.parallaxoffset.value
        let top = this.mainTitleRef.current.getBoundingClientRect().top
        let height =
          this.mainTitleRef.current.getBoundingClientRect().bottom - top
        let percentShown =
          (window.innerHeight - top) / (height + window.innerHeight)
        if (percentShown < 0) percentShown = 0
        else if (percentShown > 1) percentShown = 1
        this.mainTitleRef.current.style.transform = `translateY(${offset *
          percentShown}px)`
      }

      /* Calculate Background Color Shift for Case Studies Page */
      let offsetTop = this.containerRef.current.getBoundingClientRect().top
      let halfWinHeight = window.innerHeight / 2

      if (offsetTop < halfWinHeight) {
        {
          /*
        this.setState({
          topLayerOpacity: 1 - (halfWinHeight - offsetTop) / halfWinHeight,
        })
        */
        }
        this.setState({ topLayerOpacity: 0 })
      } else {
        this.setState({ topLayerOpacity: 1 })
      }
      if (offsetTop < -halfWinHeight) {
        {
          /*
        this.setState({
          middleLayerOpacity:
            1 - Math.abs((halfWinHeight + offsetTop) / halfWinHeight),
        })
        */
        }
        this.setState({ middleLayerOpacity: 0 })
      } else {
        this.setState({ middleLayerOpacity: 1 })
      }
    }
  }

  mouseEnterHoverBox = () => {
    this.setState({
      isMouseInHoverBox: true,
      arrowURL: this.props.homePage ? rightArrowBlue : rightArrowWhite,
    })
  }

  mouseLeaveHoverBox = () => {
    this.setState({
      isMouseInHoverBox: false,
      arrowURL: this.props.homePage ? rightArrowDark : rightArrowWhite,
    })
  }

  mouseEnterLink = () => {
    this.setState({
      arrowURL: this.props.homePage ? rightArrowBlue : rightArrowWhite,
      isMouseInHoverBox: true,
    })
  }

  mouseLeaveLink = () => {
    this.setState({
      arrowURL: this.props.homePage ? rightArrowDark : rightArrowWhite,
    })
  }

  render() {
    {
      /* Left Alignment class assigned to hoverBox, imageGroup, backgroundTitle
        and description
        could separate leftAligned and onHomePage with += */
    }
    let titleString = this.props.title
    let portfolioSectionClass = cx('portfolioSection', titleString, {
      homePage: this.props.homePage,
      isLeftAligned: this.props.isLeftAligned,
    })
    let imageGroupClass = cx('imageGroup', {
      isLeftAligned: this.props.isLeftAligned,
    })
    let hoverBoxClass = cx('hoverBox')
    let backgroundTitleClass = cx('backgroundTitle', {
      isLeftAligned: this.props.isLeftAligned,
      homePage: this.props.homePage,
    })
    let hiddenSketchClass = cx('hiddenSketch', {
      onHover: this.state.isMouseInHoverBox,
    })
    let mainImageClass = cx('mainImage', {
      homePage: this.props.homePage,
      onHover: this.state.isMouseInHoverBox,
    })
    let overlayClass = cx('imageOverlay', {
      homePage: this.props.homePage,
      onHover: this.state.isMouseInHoverBox,
    })
    let descriptionClass = cx('description', {
      isLeftAligned: this.props.isLeftAligned,
      homePage: this.props.homePage,
      onHover: this.state.isMouseInHoverBox,
    })
    let keywordsTitleLinkGroupClass = cx('keywordsTitleLinkGroup', {
      isLeftAligned: this.props.isLeftAligned,
      homePage: this.props.homePage,
    })
    let keywordsClass = cx('keywords', {
      homePage: this.props.homePage,
    })
    let mainTitleClass = cx('mainTitle', {
      homePage: this.props.homePage,
    })
    let checkItOutLinkClass = cx('checkItOutLink', {
      homePage: this.props.homePage,
      onHover: this.state.isMouseInHoverBox,
    })

    const sketchSources = [
      {
        ...this.props.sketchImage.desktopImage,
        media: `(min-width: 426px)`,
      },
      {
        ...this.props.sketchImage.mobileImage,
        media: `(max-width: 425px)`,
      },
    ]

    const whiteSketchSources = [
      {
        ...this.props.whiteSketchImage.desktopImage,
        media: `(min-width: 426px)`,
      },
      {
        ...this.props.whiteSketchImage.mobileImage,
        media: `(max-width: 425px)`,
      },
    ]

    return (
      <div
        ref={this.containerRef}
        className={portfolioSectionClass}
        style={{
          backgroundColor: this.props.homePage ? 'none' : this.props.nextColor,
        }}
      >
        {!this.props.homePage && (
          <div
            className={styles.layerContainer}
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              top: 0,
              left: 0,
            }}
          >
            {/* middle layer == brand color */}
            <div
              className={styles.middleLayer}
              style={{
                position: 'absolute',
                width: '100vw',
                height: '100vh',
                top: 0,
                left: 0,
                backgroundColor: this.props.backgroundColor,
                transition: 'opacity 0.5s ease',
                opacity: this.state.middleLayerOpacity,
              }}
            />
            {/* top layer == prev color */}
            <div
              className={styles.topLayer}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: this.props.prevColor,
                transition: 'opacity 0.5s ease',
                opacity: this.state.topLayerOpacity,
              }}
            />
          </div>
        )}
        {!this.props.homePage && (
          <div className={styles.sidebar} style={{ zIndex: '100' }}>
            <div className={styles.sidebarTitle}>{this.props.title}</div>

            <ScrollElement
              prev={this.props.prev}
              next={this.props.next}
              color="white"
            />
          </div>
        )}
        <Link
          to={`/${this.props.slug}/`}
          onMouseEnter={this.mouseEnterHoverBox.bind(this)}
          onMouseLeave={this.mouseLeaveHoverBox.bind(this)}
          className={hoverBoxClass}
        />
        {/* Left Subgroup */}
        <div className={styles.subdivider}>
          <div className={backgroundTitleClass}>{this.props.title}</div>

          {/* Image Group */}

          <div className={imageGroupClass}>
            <div
              ref={this.imageRef}
              parallaxoffset={isMobile ? -30 : -60}
              offsetymax={'135px'}
              offsetymin={'-75px'}
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
              }}
            >
              <Img
                fadeIn={true}
                fluid={this.props.homePage ? sketchSources : whiteSketchSources}
                className={hiddenSketchClass}
                style={{ position: 'absolute' }}
              />
              <Img
                fadeIn={true}
                fluid={this.props.thumbnailImage.fluid}
                className={mainImageClass}
              />
              <div className={overlayClass} />
            </div>
          </div>
          <div className={descriptionClass}>{this.props.description}</div>
        </div>
        {/* Right Subgroup */}
        <div className={styles.subdivider}>
          <div className={keywordsTitleLinkGroupClass}>
            <div
              ref={this.keywordsRef}
              parallaxoffset={isMobile ? -60 : -80}
              offsetymax={'75px'}
              offsetymin={'-30px'}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 15,
              }}
            >
              <div className={keywordsClass}>{this.props.keywords}</div>
            </div>

            <div
              ref={this.mainTitleRef}
              parallaxoffset={isMobile ? -90 : -105}
              offsetymax={'240px'}
              offsetymin={'-160px'}
              style={{
                position: 'absolute',
                top: '16px',
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 15,
              }}
            >
              <div className={mainTitleClass}>{this.props.title}</div>

              <Link
                to={`/${this.props.slug}/`}
                onMouseEnter={this.mouseEnterLink.bind(this)}
                className={checkItOutLinkClass}
              >
                Check it out &nbsp; &nbsp;
                <img
                  className={styles.arrow}
                  src={this.state.arrowURL}
                  alt=""
                />{' '}
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CaseStudyItem
